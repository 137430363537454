import { Button, Text } from "@/components/common";
import { APP_NAME } from "@/config/common";
import Image from "next/image";
import googleImg from "@/assets/google.svg";
import { firebaseGoogleLogin, onFirebaseAuthStateChanged } from "@/utils/firebase";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import { PublicLayout } from "@/layouts";
import Link from "next/link";
import logoImg from "@/assets/logo.svg";

export default function Login() {
  const router = useRouter();
  const [authenticating, setAuthenticating] = useState(false);
  const [checkingAuth, setCheckingAuth] = useState(true);

  const handleGoogleLogin = () => {
    firebaseGoogleLogin()
      .then(() => router.push(router.query.next ? decodeURIComponent(router.query.next as string) : "/app"))
      .finally(() => setAuthenticating(false));
    setAuthenticating(true);
  };

  useEffect(() => {
    const unsubscribe = onFirebaseAuthStateChanged((authUser) => {
      if (authUser) {
        void router.push(router.query.next ? decodeURIComponent(router.query.next as string) : "/app");
      } else setCheckingAuth(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <PublicLayout back="/" hideMenu hideFooter>
      <Head>
        <title>Login - {APP_NAME}</title>
      </Head>

      <div className="h-full grid place-items-center px-8">
        <div className="w-full max-w-[300px] flex flex-col items-center gap-6">
          <Image src={logoImg} className="max-w-[288px]" alt="App logo" />

          <Button loading={authenticating || checkingAuth} onClick={handleGoogleLogin} flex>
            <Image src={googleImg} width={24} alt="Login with google" />
            Login with Google
          </Button>

          <Text align="center">
            By continuing, you agree to our{" "}
            <Link href="/terms-of-service" className="underline" target="_blank">
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link href="/privacy-policy" className="underline" target="_blank">
              Privacy Policy
            </Link>{" "}
            agreements.
          </Text>

          {/* <div className="w-full"> */}
          {/*   <Link href="/login/phone"> */}
          {/*     <Button className="h-12" loading={authenticating} flex> */}
          {/*       Login with phone */}
          {/*     </Button> */}
          {/*   </Link> */}

          {/*   <Text size="sm" align="center" className="mt-2"> */}
          {/*     For better security, prefer Google login */}
          {/*   </Text> */}
          {/* </div> */}
        </div>
      </div>
    </PublicLayout>
  );
}
